import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Case studies" />
    <h1>First case study</h1>
    <p>Lorem Ipsum</p>
    <Link to="/">Go home</Link>
  </Layout>
)

export default SecondPage
